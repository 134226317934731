@font-face {
    src: local("Roboto"), url('@assets/font/Roboto_w300.woff2') format("woff2");
    font-family: "Roboto";
    font-weight: 300;
}

@font-face {
    src: local("Roboto"), url('@assets/font/Roboto.woff2') format("woff2");
    font-family: "Roboto";
    font-weight: normal;
}

@font-face {
    src: local("Roboto"), url('@assets/font/Roboto_w500.woff2') format("woff2");
    font-family: "Roboto";
    font-weight: 500;
}

@font-face {
    src: local("Roboto"), url('@assets/font/Roboto_bold.woff2') format("woff2");
    font-family: "Roboto";
    font-weight: bold;
}

body {
    margin: 0;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0 auto !important;
    float: none !important;
}

code {
    font-family: "Roboto", sans-serif;
}

html,
body,
#root {
    height: 100%;
}

#apelio_content_container li {
    color: #4b4b4b;
}

#apelio_content_container h2 {
    color: #203956;
}

#apelio_content_container h3 {
    color: #203956;
}

#apelio_content_container h4 {
    color: #203956;
}

#apelio_content_container figure img {
    border: 5px solid #5BA0F2;
}

#apelio_content_container figcaption {
    text-align: center;
    font-size: 18px;
    color: #A0A0A0;
}

#apelio_content_container figure {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}